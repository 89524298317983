import { Carousel, Col, Image, Row, Typography } from "antd";

const { Title } = Typography;

// TODO: use a different image for image 1

const images = [
  "/img/seals/2.JPG",
  "/img/seals/2.JPG",
  "/img/seals/3.JPG",
  "/img/seals/4.JPG",
  "/img/seals/5.JPG",
  "/img/seals/6.JPG",
  "/img/seals/7.JPG",
  "/img/seals/8.JPG",
];

const imagesDoubleIndex = images.slice(0, Math.ceil(images.length / 2));

const Landing = ({ isMobile }: { isMobile: boolean }) => {
  console.log(imagesDoubleIndex);
  imagesDoubleIndex.forEach((_, index) => {
    console.log(index);
  });

  return (
    <Row className="row-padding" justify="space-around" align="middle">
      <Col span={20}>
        <Title>Globaleps</Title>

        <Carousel autoplay infinite={false} dotPosition="top">
          {isMobile
            ? images.map((image, index) => (
                <Image
                  key={index}
                  width={"100%"}
                  src={process.env.PUBLIC_URL + image}
                ></Image>
              ))
            : imagesDoubleIndex.map((_, index) => (
                <Image.PreviewGroup key={2 * index}>
                  <Image
                    key={2 * index}
                    width={"50%"}
                    src={process.env.PUBLIC_URL + images[2 * index]}
                  ></Image>
                  {2 * index + 1 < images.length && (
                    <Image
                      key={2 * index + 1}
                      width={"50%"}
                      src={process.env.PUBLIC_URL + images[2 * index + 1]}
                    ></Image>
                  )}
                </Image.PreviewGroup>
              ))}
        </Carousel>
      </Col>
    </Row>
  );
};

export default Landing;
