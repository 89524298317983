import { Col, Image, Row, Space, Typography } from "antd";

const { Title } = Typography;

const logos = [
  { src: process.env.PUBLIC_URL + "/img/logos/Baylor.png", alt: "Baylor University logo" },
  { src: process.env.PUBLIC_URL + "/img/logos/RivasAiLab.png", alt: "Rivas AI logo" },
  { src: process.env.PUBLIC_URL + "/img/logos/Happywhale.png", alt: "Happywhale logo" },
  { src: process.env.PUBLIC_URL + "/img/logos/WCS.png", alt: "Wildlife Conservation Society logo" },
  { src: process.env.PUBLIC_URL + "/img/logos/AMLR.png", alt: "" },
  {
    src: process.env.PUBLIC_URL + "/img/logos/CEAL_Lab.png",
    alt: "Comparative Ecophysiology of Animals Lab logo",
  },
  {
    src: process.env.PUBLIC_URL + "/img/logos/CONAF.png",
    alt: "Corporación Nacional Forestal of Argentina Logo",
  },
  {
    src: process.env.PUBLIC_URL + "/img/logos/DNA_Argentina.png",
    alt: "Dirección Nacional del Antártico of Argentina logo",
  },
  {
    src: process.env.PUBLIC_URL + "/img/logos/MIMMP.png",
    alt: "Marion Island Marine Mammal Programme logo",
  },
];

const Collaborators = () => {
  return (
    <Row className="row-padding" justify="space-around" align="middle">
      <Col span={20}>
        <Title>Collaborators</Title>
        <Space size={"middle"} wrap={true}>
          {logos.map((logo, index) => (
            <Image
              key={index}
              width={"15rem"}
              src={logo.src}
              alt={logo.alt}
              preview={false}
            ></Image>
          ))}
        </Space>
        <Image.PreviewGroup></Image.PreviewGroup>
      </Col>
    </Row>
  );
};

export default Collaborators;
