import { Col, Divider, GetProp, Menu, MenuProps, Row } from "antd";
import logo from "../logo.svg";
import { colors } from "../styles";

const HeaderContents = ({ items }: { items: GetProp<MenuProps, "items"> }) => {
  return (
    <>
      <Row
        style={{
          padding: "0 50px",
          height: "50%",
          background: colors.baylorGreen,
        }}
        align="middle"
      >
        <Col span={2} style={{ height: "100%" }}>
          <img
            src={logo} // TODO: Replace with your own logo
            alt={"Globaleps logo"}
            style={{ height: "inherit", padding: "1px" }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          margin: 0,
          color: colors.baylorGold,
          background: colors.baylorGold,
        }}
      />
      <Row
        style={{
          padding: "0 50px",
          height: "50%",
          background: colors.backgroundNav,
        }}
      >
        <Col span={14} style={{ height: "100%" }}>
          <Menu
            style={{ height: "100%", background: "inherit" }}
            mode="horizontal"
            items={items}
          ></Menu>
        </Col>
      </Row>
    </>
  );
};

export default HeaderContents;
