import { Col, ConfigProvider, List, Row, Typography } from "antd";

const { Title, Paragraph, Link } = Typography;

const NSF = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          List: {
            itemPadding: "0.2rem 0.4rem",
          },
        },
      }}
    >
      <Row className="row-padding" justify="space-around" align="middle">
        <Col span={20}>
          <Title>NSF Project: Move, adapt, or change</Title>

          <Paragraph>
            Leopard seals (<i>Hydrurga leptonyx</i>) are large, wide-ranging,
            and important predators that live in Antarctica and across the
            Southern Ocean. Leopard seals are top predators that eat many
            different types and sizes of prey, ranging from Antarctic krill to
            other species of seals.
          </Paragraph>

          <Paragraph>
            Polar regions, like the Southern Ocean, are experiencing rapid
            environmental change, from melting sea ice to rising temperatures.
            These environmental changes dramatically impact the biology of polar
            species that live in these habitats, like leopard seals.
          </Paragraph>

          <Paragraph>
            Unfortunately, little is known about the biology of leopard seals,
            so it is unclear how leopard seals are being affected by climate
            change and habitat alternations across the Southern Ocean.
            Therefore, our global research team's is working to answer the
            question is:{" "}
            <strong>
              How are leopard seals adapting and responding to environmental
              changes in the Southern Ocean?
            </strong>
          </Paragraph>

          <Paragraph>
            To answer this question, we have formed a collaborative team of
            leopard seal researchers from around the world. We are examining the
            adaptive capacity of leopard seals. Adaptive capacity is a way to
            measure the ability of a species to respond to changes in the
            environment.
          </Paragraph>

          <Title level={4}>
            Our team is measuring leopard seals' adaptive capacity by:
          </Title>
          <List
            style={{ paddingBottom: "0.4rem" }}
            dataSource={[
              "Determining their species distribution and movement patterns",
              "Examining their genetic diversity",
              "Assessing variability in ecological and physiological traits across their range",
            ]}
            renderItem={(item, index) => (
              <List.Item>
                <Typography.Text>{index + 1}.</Typography.Text> {item}
              </List.Item>
            )}
            split={false}
          ></List>

          <Paragraph>
            To do this, we are combining historic and current data on leopard
            seals from across the Southern Ocean to better understand the
            biology – and adaptive capacity – of this incredible and
            understudied large predator.
          </Paragraph>

          <Paragraph>
            For more information, please visit the{" "}
            <Link href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2146068">
              NSF Award
            </Link>{" "}
          </Paragraph>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default NSF;
