import React, { useEffect, useState } from "react";
import { defaultTheme } from "./styles";
import "./App.css";
import {
  ConfigProvider,
  Layout,
  Typography,
  GetProp,
  MenuProps,
  Menu,
} from "antd";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import {
  HomeOutlined,
  LinkOutlined,
  AimOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import HeaderContents from "./components/HeaderContents";
import Landing from "./pages/Landing/Landing";
import NSF from "./pages/NSF";
import Collaborators from "./pages/Collaborators";
import Sider from "antd/es/layout/Sider";

const { Link } = Typography;

const { Content, Header } = Layout;

type MenuItem = GetProp<MenuProps, "items">[number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link href="/">Home</Link>, "1", <HomeOutlined />),
  getItem(<Link href="/nsf">Aims of NSF</Link>, "2", <AimOutlined />),
  getItem(
    <Link href="/collaborators">Collaborators</Link>,
    "3",
    <TeamOutlined />
  ),
  getItem(
    <Link href="https://happywhale.com/submitMedia" target="_blank">
      Submit Photos to Happywhale
    </Link>,
    "4",
    <LinkOutlined />
  ),
];

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ConfigProvider theme={defaultTheme}>
      <Router>
        <Layout>
          {!isMobile ? (
            <Header style={{ height: "128px" }}>
              <HeaderContents items={items} />
            </Header>
          ) : (
            <Sider
              collapsible
              collapsed={collapsed}
              collapsedWidth={0}
              onCollapse={(value) => setCollapsed(value)}
            >
              <Menu
                theme="dark"
                defaultSelectedKeys={["1"]}
                mode="inline"
                items={items}
              />
            </Sider>
          )}
          <Content>
            <Routes>
              <Route path="/" element={<Landing isMobile={isMobile} />} />
              <Route path="/nsf" element={<NSF />} />
              <Route path="/collaborators" element={<Collaborators />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Content>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

export default App;
