import { ThemeConfig } from "antd";

const colors = {
  baylorGreen: "#154734",
  baylorGold: "#ffb81c",
  backgroundNav: "#383838",
};

const defaultTheme: ThemeConfig = {
  components: {
    Layout: {
      headerPadding: "0 0",
    },
    Menu: {
      horizontalItemHoverColor: colors.baylorGold,
      horizontalItemSelectedColor: colors.baylorGold,
      itemHoverColor: colors.baylorGold,
      itemSelectedColor: "#e81123",
      itemColor: "white",
    },
  },
};

export { colors, defaultTheme };
